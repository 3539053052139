<template>
  <div
      class="
      flex
      min-h-full
      w-full
      main-preloader
      justify-content-center
      align-item-center
    "
      v-if="isPreloader"
  >
    <preloader/>
  </div>
  <div class="flex min-h-full w-full" v-if="!isRedirect && !registrationRole">
    <Header/>
    <header-second/>
    <div class="page-container">
      <router-view/>
      <div class="mt-5 documents-link">
        <a
            href="https://cabinet.way2wei.ru/documents/politic.pdf"
            target="_blank"
        >Политика в отношении обработки персональных данных</a
        >
      </div>
    </div>
    <div v-if="isGlobalLoad" class="page-preloader">
      <preloader/>
    </div>
    <notifications
        animation-name="notif"
        :duration="10000"
        :max="5"
        :closeOnClick="true"
        classes="vue-notification custom-modifications"
    />
  </div>
  <redirect-page v-if="isRedirect"/>
  <registration-role @roleChoice="selectedRole" v-if="registrationRole"/>
  <popup-technical-work
      v-if="showTechnicalModal"
      @closeModal="showTechnicalModal = false"
      :title="`На сайте ведутся техничекие работы`"
  ></popup-technical-work>
  <pop-up-change-password
      v-if="profile && isChangeRequired"
      :profile="profile"
      @close = "isChangeRequired = false"
  />
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import $ from "jquery";
import axios from "axios";
import Header from "./components/Header/Header.vue";
import HeaderSecond from "./components/Header/HeaderSecond.vue";
import useBreakpoints from "./mixins/useBreakpoints";
import useCheckVersion from "./mixins/useCheckVersion";
import RedirectPage from "./components/Technical/RedirectPage.vue";
import RegistrationRole from "./components/Technical/RegistrationRole.vue";
import {useRouter} from "vue-router";
import {notify} from "@kyvg/vue3-notification";
import Preloader from "./components/Technical/Preloader.vue";
import useCheckStatusTechnicalWork from "./mixins/useCheckTechnicalWork";
import popupTechnicalWork from "@/components/popup-technical-work/PopupTechnicalWork.vue";
import PopUpChangePassword from "@/components/Popups/PopUpChangePassword.vue";

export default {
  components: {
    PopUpChangePassword,
    Header,
    HeaderSecond,
    RedirectPage,
    RegistrationRole,
    Preloader,
    popupTechnicalWork
  },
  provide: {
    imageUrl: process.env.VUE_APP_BACK,
  },
  setup() {
    const store = useStore(),
        router = useRouter();

    const {checkStatusTechnicalWork} = useCheckStatusTechnicalWork();
    const {width} = useBreakpoints();
    const isRedirect = computed(() => store.state.redirectProcess),
        profile = computed(() => store.state.profile.profile),
        companyTrap = computed(() => store.state.companyTrap),
        userCompany = computed(() => store.state.company.userCompany),
        isGlobalLoad = computed(() => store.state.isGlobalLoad),
        registrationRole = ref(false),
        isPreloader = ref(false),
        isChangeRequired = ref(false);

    const {checkVersion} = useCheckVersion();

    let showTechnicalModal = ref(false),
        currentStatusTechnicalWork = ref('')

    axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (error.message === 'Network Error') {
            showTechnicalModal.value = true
          }
          if (error.response) {
            checkStatusTechnicalWork()
          }
        });

    const selectedRole = (role) => {
      const updatedProfile = profile.value;
      updatedProfile.role = role;
      store
          .dispatch("profile/updateProfile", updatedProfile)
          .then(() => {
            registrationRole.value = false;
            //Тут определяем что делать при какой роли
            if (role == "private_person") {
              router.push("/");
            } else {
              //Ловушка на компанию которая не дает перейти никуда кроме компании ехехехех
              store.commit("setCompanyTrap", true);
              router.push("/company");
            }
          })
          .catch((error) => {
            notify({
              title: "Ошибка",
              text: error,
              type: "error",
            });
          });
    };

    router.beforeEach((to, from) => {
      store.commit("setIsGlobalLoad", false);
      !from.name
          ? store.commit("setShowBack", false)
          : store.commit("setShowBack", true);
      if (companyTrap.value && to.path !== "/company") {
        return "/company";
      }
    });

    onBeforeMount(() => {
      isPreloader.value = true;
      // Запись токена авторизации
      let tokenList = document.cookie.match(new RegExp("ei-token=([^;]*)"))

      if (tokenList) {
        let token = tokenList[1];

        const user = {
          token: `Bearer ${decodeURI(token)}`,
        };
        let mainToken = document.cookie.match(
            new RegExp("ei-token-main=([^;]*)")
        );
        if (mainToken) {
          user.externalEnter = true;
        }

        axios.defaults.headers.common = {
          Authorization: `Bearer ${decodeURI(token)}`,
          "Content-Type": "application/json",
        };
        axios.defaults.withCredentials = process.env.NODE_ENV === 'production';
        store.commit("setUser", user);
        currentStatusTechnicalWork.value = localStorage.getItem("statusTechnicalWork");
      }

      store
          .dispatch("profile/getProfile")
          .then(() => {
            if (profile.value.password_change_required) isChangeRequired.value = true;
            if (!profile.value.role) registrationRole.value = true;
            if (profile.value.role == "director") {
              store.dispatch("company/getUserCompany").then(() => {
                if (!userCompany.value.name || !userCompany.value.inn) {
                  store.commit("setCompanyTrap", true);
                  router.push("/company");
                }
              });
            }
          })
          .finally(() => (isPreloader.value = false));
      store.dispatch("partners/getCurrentPartner");
      store.dispatch("partners/getCurrentExpert");
      store.commit("setWidth", width);
      // Автоматический фокус на текстовом поле при открытии select2
      $(document).on("select2:open", (e) => {
        const selectId = e.target.id;
        $(
            ".select2-search__field[aria-controls='select2-" +
            selectId +
            "-results']"
        ).each(function (key, value) {
          value.focus();
        });
      });
      checkVersion();
    });

    watch(currentStatusTechnicalWork, () => {
      if (currentStatusTechnicalWork.value !== '200') {
        showTechnicalModal.value = true
      }
    })

    return {
      isRedirect,
      registrationRole,
      profile,
      router,
      selectedRole,
      companyTrap,
      userCompany,
      isPreloader,
      isGlobalLoad,
      showTechnicalModal,
      isChangeRequired,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/css/kf";
@import "./assets/css/common.scss";
@import "./assets/css/print.scss";
@import "./assets/css/poppers-styles.scss";

body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.538;
  font-weight: 200;
  background-color: #f4f4f4;
  overflow-y: hidden;
}

:root {
  --xs-continer-gap: 30px;
  --main-color: #2cc185;
  --main-color70: #2cc185b9;
  --focus-color: #89e4bf;
  --hover-color: #337f61;
  --light-color: #7fd3b0;
  --dark-color: #323232;
  --cream-color: #eceeef;
  --common-text-color: #545454;
  --chart-color: #2cc185;
  --chart-line-color: #2c68c1;
  --icons-color: #45a780;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::selection {
  color: white;
  background-color: var(--hover-color);
}

a:active,
:active {
  outline: none;
}

:focus {
  // outline: none;
}

// a:focus {
//   color: var(--hover-color) !important;
// }

nav,
footer,
header,
aside,
main,
details {
  display: block;
}

html,
body,
#app {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1.538;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  position: relative;
}

#app {
  position: unset;
  margin-top: 62px;
}

.main-preloader {
  background-color: white;
  z-index: 15;
  position: absolute;
  opacity: 95%;
  top: 0;
  left: 0;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button {
  background-color: transparent;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

[hidden] {
  display: none;
}
</style>
