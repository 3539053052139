import {notify} from "@kyvg/vue3-notification";
import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        users: [],
        user: null,
        userId: null,
        userToken: null,
        totalUsers: null,
        userTrajectories: null,
        userBlock:null
    }),

    mutations: {
        setUser(state, data) {
            state.user = data;
        },
        setUsers(state, data) {
            state.users = data;
        },
        setUserId(state, data) {
            state.userId = data;
        },
        setUserToken(state, data) {
            state.userToken = data;
        },
        setTotalUsers(state, data) {
            state.totalUsers = data;
        },
        setUserTrajectories(state, data) {
            state.userTrajectories = data;
        },
        setUserBlock(state, data) {
            state.userBlock = data;
        }
    },

    actions: {
        // eslint-disable-next-line no-unused-vars
        async createUser({commit}, data) {
            await axios.post(`/users`, data)
                .then(({data}) => {
                    commit("setUserId", data.id)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                });
        },
        async getUser({commit}, id) {
            await axios.get(`/users/${id}`)
                .then(({data}) => {
                    commit("setUser", data)
                })
        },
        async getUsers({commit}, data) {
            await axios.get(`/users?per_page=${data.perPage}&page=${data.page}&${data.sort ? data.sort : ''}`)
                .then(({data}) => {
                    commit("setUsers", data.users)
                    commit("setTotalUsers", data.meta.total)
                })
        },
        async getUsersBySearch({commit}, data) {
            data.email = encodeURIComponent(data.email);
            await axios.get(`/users/staffSearch?per_page=${data.perPage}&page=${data.page}&name=${data.name}&email=${data.email}&role=${data.role}`)
                .then(({data}) => {
                    commit("setUsers", data.users)
                    commit("setTotalUsers", data.meta.total)
                })
        },
        async updateUser({commit}, data) {
            await axios.put(`/users/${data.id}`, data.form)
                .then(({data}) => {
                    commit("setUser", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async getUserToken({commit}, id) {
            await axios.get(`/users/${id}/token`)
                .then(({data}) => {
                    commit("setUserToken", data)
                })
        },
        async getUserTrajectories({commit}, id) {
            await axios.get(`/users/${id}/trajectories`)
                .then(({data}) => {
                    commit("setUserTrajectories", data)
                })
        },
        async quotaAppend({commit}, data) {
            await axios.post(`/users/quota/append`, data)
                .then(({data}) => {
                    notify({
                        title: "Приглашение отправлено",
                        type: "success"
                    })
                })
        },
        async quotaRemove({commit}, data) {
            await axios.post(`/users/quota/remove`, data)
                .then(({data}) => {
                    notify({
                        title: "Приглашение отозвано",
                        type: "success"
                    })
                })
        },
        async blockUser({commit}, data) {
            await axios.get(`/users/${data.email}/blocked`)
                .then(({data}) => {
                    commit("setUserBlock", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        }
    }
}
